import React from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Avatar,
  Theme,
} from "@mui/material";
//@ts-ignore
// import FeatherIcon from "feather-icons";
import profilecover from "../../assets/user-1.jpg";
import userimg from "../../assets/images/users/user2.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import { StandardCard } from "../Cards";
import { Upload } from "@mui/icons-material";

interface IProfileIntroCardProps {
  profile_url: string;
  onChange: (file: File | null) => void;
}

export const ProfileIntroCard: React.FC<IProfileIntroCardProps> = ({
  profile_url,
  onChange,
}) => {
  const [profilePhoto, setProfilePhoto] = React.useState<string | null>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      const fileUrl = URL.createObjectURL(file);
      setProfilePhoto(fileUrl);
      onChange(file);
    }
  };

  const handleClearFile = () => {
    setProfilePhoto(null);
    onChange(null);
  };

  return (
    <>
      <Box>
        <Typography variant="h3">Change Profile</Typography>
        <Typography variant="body2">
          Change your profile picture from here
        </Typography>{" "}
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        marginTop={3}
      >
        <Box>
          {profilePhoto || profile_url?.length > 0 ? (
            <img
              src={profilePhoto || profile_url}
              alt="log not found"
              style={{ height: "150px", width: "150px", borderRadius: "100px" }}
            />
          ) : (
            <img
              src={profilecover}
              alt="log not found"
              style={{ height: "150px", borderRadius: "100px" }}
            />
          )}
        </Box>

        <Box display={"flex"} marginTop={3}>
          <Box sx={{ mr: 1 }}>
            <input
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              onChange={onFileChange}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                sx={{ background: "warning.main" }}
              >
                Upload
              </Button>
            </label>
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleClearFile}>
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
