import { lazy } from "react";
import { MODULE_IDS } from "../../../constants/enums";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { APP_ADVANCE_EXPENSES_ROUTE } from "./AdvanceExpences.constants";

const AdvanceExpencesTable = Loadable(
  lazy(() =>
    import("./AdvanceExpencesTable").then(({ AdvanceExpencesTable }) => ({
      default: AdvanceExpencesTable,
    })),
  ),
);

const ManageAdvanceExpense = Loadable(
  lazy(() =>
    import("./ManageAdvanceExpense").then(({ ManageAdvanceExpense }) => ({
      default: ManageAdvanceExpense,
    })),
  ),
);

export const advanceExpensesRoutes = [
  {
    path: APP_ADVANCE_EXPENSES_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
        <AdvanceExpencesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_ADVANCE_EXPENSES_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
        <ManageAdvanceExpense />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_ADVANCE_EXPENSES_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.EXPENSE]}>
        <ManageAdvanceExpense />
      </AuthorizedRoute>
    ),
  },
];
