import { LoadState } from "../../constants/enums";
import {
  IExpense,
  IExpenseApproval,
  IExpenseCategory,
  IExpenseModuleState,
} from "./expenseModule.types";

export const defaultExpense: IExpense = {
  expense_type: "EXPENSE",
  expense_uuid: null,
  report_name: null,
  report_uuid: null,
  project_name: "",
  project_uuid: "",
  user_uuid: null,
  user_name: null,
  created_by_name: "",
  project_manager_uuid: "",
  project_manager_name: "",
  finance_manager_uuid: "",
  finance_manager_name: "",
  job_order_no: null,
  job_uuid: null,
  job_name: null,
  department_uuid: "",
  department_name: "",
  expense_date: "",
  receipt: null,
  merchant: null,
  expense_category_uuid: null,
  expense_category_name: null,
  category_manager_name: "",
  category_manager_uuid: "",
  amount: null,
  description: null,
  business_purpose: null,
  advance_amount: null,
  requested_advance_amount: null,
  reimbursed_amount: null,
  eligible_reimbursement_amount: null,
  status: "EXPENSE_REQUESTED",
  insert_ts: undefined,
  create_ts: undefined,
  rowId: undefined,
  requested_by_uuid: "",
  is_user_approver: false,
  is_deduct_from_advance: false,
  approval_uuid: null,
};

export const defaultExpenseCategory: IExpenseCategory = {
  expense_category_uuid: null,
  expense_category_name: "",
  expense_category_description: null,
  status: "ACTIVE",
  category_manager_name: "",
  category_manager_uuid: "",
};

export const defaultExpenseApproval: IExpenseApproval = {
  expense_type: "EXPENSE",
  report_uuid: null,
  report_name: null,
  project_uuid: "",
  project_name: "",
  project_manager_name: "",
  project_manager_uuid: "",
  finance_manager_name: "",
  finance_manager_uuid: "",
  department_uuid: "",
  department_name: "",
  expense_uuid_list: [],
  business_purpose: null,
  category: null,
  advance_amount: null,
  reimbursed_amount: null,
  description: null,
  approval_uuid: null,
  status: "REPORT_REQUESTED",
};

export const defaultExpenseModuleState: IExpenseModuleState = {
  expenseList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseData: {
    data: defaultExpense,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseCategoryList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseCategoryData: {
    data: defaultExpenseCategory,
    loading: LoadState.NotLoaded,
    error: null,
  },

  expenseApprovalList: {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  expenseApprovalData: {
    data: defaultExpenseApproval,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
